import React from "react";

import Hero from "./hero";
import Menu from "./menu";
import Reserve from "./reserve";

function App() {
  return (
    <div className="App">
      <Hero />
      <Menu />
      <Reserve />
    </div>
  );
}

export default App;
