import React from "react";
import "./style.css";

function Reserve() {
  return (
    <div className="reserve">
      <a href="https://forms.gle/xqtswKSvS8gRyEEa8" className="button">
        <h2>Varaa pöytä</h2>
      </a>
    </div>
  );
}

export default Reserve;
