import React from "react";
import "./style.css";

function Menu() {
  return (
    <div className="menu">
      <h2>Menu vaihtoehdot</h2>
      <p>
        Juomapaketti: Vesi, maito, piña colada mocktail, puna- tai valkoviini
      </p>
      <div className="menuOptions">
        <div className="menuOption">
          <h3>Metsästäjän menu</h3>
          <h4>Alkuruoka</h4>
          <p>Uunituoretta leipää ja tuorejuustoa</p>
          <h4>Pääruoka</h4>
          <p>
            Hirvenpaistia punaviinikastikkeella, palsternakka-perunapyreetä ja
            raikasta salaattia vinegretellä
          </p>
          <h4>Jälkiruoka</h4>
          <p>suklaafondueta & mansikkaa (tumma-, maito- tai valkosuklaa)</p>
        </div>
        <div className="divider"></div>
        <div className="menuOption">
          <h3>Kalastajan menu</h3>
          <h4>Alkuruoka</h4>
          <p>Hapanjuurileipä oliiviöljy-balsamicolla</p>
          <h4>Pääruoka</h4>
          <p>
            Tagliatelle pastaa sitruunaisella lohella, lisukkeena Pecorino
            juustoa & tuoreita yrttejä ja pirteää omenasalaattia{" "}
          </p>
          <h4>Jälkiruoka</h4>
          <p>Pätkiskakkua vadelmalla & kermavaahdolla</p>
        </div>
      </div>
    </div>
  );
}

export default Menu;
