import "./style.css";

function Hero() {
  return (
    <div className="container">
      <div className="hero">
        <div className="bg"></div>
        <h1 className="title">Milmu</h1>
        <p>Ruokaa rakkaudella Hervannan sydämessä</p>
        <div className="bottom">
          <i className="arrow down"></i>
        </div>
      </div>
    </div>
  );
}

export default Hero;
